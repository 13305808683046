import React from 'react'
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import SocialMediaAccounts from '../../data/SocialMediaAccounts'

const SocialMedia = () => {
  return (
    <MDBRow>
      <MDBCol md="3">
        <a href={SocialMediaAccounts.facebook} target="_blank">
          <MDBIcon fab icon="fab fa-facebook" size="3x" className="blue-text" />
          <h5 className="font-weight-bold my-3">Facebook</h5>
        </a>
      </MDBCol>
      <MDBCol md="3">
        <a href={SocialMediaAccounts.instagram} target="_blank">
          <MDBIcon fab icon="fab fa-instagram" size="3x" className="red-text" />
          <h5 className="font-weight-bold my-3">Instagram</h5>
        </a>
      </MDBCol>
      <MDBCol md="3">
        <a href={SocialMediaAccounts.linkedin} target="_blank">
          <MDBIcon fab icon="fab fa-linkedin" size="3x" className="blue-text" />
          <h5 className="font-weight-bold my-3">LinkedIn</h5>
        </a>
      </MDBCol>
      <MDBCol md="3">
        <a href={SocialMediaAccounts.github} target="_blank">
          <MDBIcon fab icon="fab fa-github" size="3x" className="black-text" />
          <h5 className="font-weight-bold my-3">GitHub</h5>
        </a>
      </MDBCol>
    </MDBRow>
  )
}

export default SocialMedia
