import React from 'react'
import './AboutMeText.css'

// i18n
import '../../i18n'
import { withNamespaces } from 'react-i18next'

const AboutMeText = ({ t }) => {
  return (
    <div className="about-me-text">
      <h2 className="h1-responsive font-weight-bold my-5">
        {t('AboutMeText.title')}
      </h2>
      <p className="text-container text-left lead grey-text w-responsive mx-auto mb-5">
        {t('AboutMeText.text')}
        &#128077; &#x1F609;
      </p>
    </div>
  )
}

export default withNamespaces()(AboutMeText)
