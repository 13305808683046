import React, { Component } from 'react'
import Layout from '../components/Layout/Layout'
import SocialMedia from '../components/SocialMedia/SocialMedia'
import { MDBContainer } from 'mdbreact'
import AboutMeText from '../components/AboutMeText/AboutMeText'

const App = () => {
  return (
    <>
      <Layout>
        <main>
          <MDBContainer tag="section" className="text-center my-5">
            <AboutMeText />
            <br />
            <br />
            <SocialMedia />
            <br />
            <br />
          </MDBContainer>
        </main>
      </Layout>
    </>
  )
}

export default App
